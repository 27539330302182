/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, MapInfo, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aids/itchy-ears-and-new-hearing-aids/'], ['en', 'https://www.hear.com/resources/hearing-aids/itchy-ears-and-new-hearing-aids/'], ['en-US', 'https://www.hear.com/resources/hearing-aids/itchy-ears-and-new-hearing-aids/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/itchy-ears-and-new-hearing-aids/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "itchy-ears-and-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#itchy-ears-and-new-hearing-aids",
    "aria-label": "itchy ears and new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Itchy Ears and New Hearing Aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/itchy-ears-and-hearing-aids.png",
    alt: "Man scratching ear due to new hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have new hearing aids and your ears itch, there’s no need to worry. In fact, it’s pretty normal among first-time users. The skin in the ear canal is very delicate and sensitive. So, when the domes of your ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " touch your ear canal, it can create a tickling sensation. Many people say it almost feels like a feather or hair lightly touching the skin. Like many new sensations, it’s only temporary. As your brain and body get used to it, it will pass with time. That’s why it’s important to keep wearing your hearing aids based on the recommended wearing schedule. And before you know it, the sensation will be gone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This common problem can range from mildly annoying to highly uncomfortable, but understanding its causes and implementing effective relief strategies can make a significant difference."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "common-causes-of-itchy-ears-with-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#common-causes-of-itchy-ears-with-new-hearing-aids",
    "aria-label": "common causes of itchy ears with new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Common Causes of Itchy Ears with New Hearing Aids"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ear Canal Sensitivity"), ": When you first start wearing hearing aids, your ear canals may not be used to the presence of a foreign object. This can lead to irritation and itchiness as your skin adjusts."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Allergic Reactions"), ": Some individuals may have allergic reactions to the materials used in hearing aids, such as certain plastics, silicones, or metals. This can cause itching, redness, and discomfort."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Moisture and Hygiene Issues"), ": Ear canals are naturally moist environments, and the addition of hearing aids can exacerbate this, leading to conditions that favor bacterial or fungal growth. Poor hygiene or insufficient cleaning of hearing aids can further contribute to itchiness."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Incorrect Fit"), ": A poorly fitting hearing aid can cause friction and pressure points within the ear canal, resulting in irritation and itchiness. This is particularly common if the hearing aid is too loose or too tight."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ear Wax Buildup"), ": Hearing aids can sometimes push earwax deeper into the ear canal, causing a blockage. This not only impacts hearing aid performance but can also lead to itching and discomfort."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you notice your skin is dry, irritated, or the itching doesn’t decrease, here’s what we recommend:"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "tips-to-relieve-itchy-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tips-to-relieve-itchy-ears",
    "aria-label": "tips to relieve itchy ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tips to Relieve Itchy Ears"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Proper Cleaning and Maintenance"), ": Regularly clean your hearing aids according to the manufacturer's instructions. Use a soft, dry cloth to wipe them down daily and remove any earwax or debris that may have accumulated."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Use Hypoallergenic Materials"), ": If you suspect an allergic reaction, consult with your audiologist about switching to hypoallergenic hearing aids or using protective coatings that reduce contact with irritating materials."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ensure a Proper Fit"), ": Visit your audiologist to ensure that your hearing aids are correctly fitted. Custom-molded hearing aids may provide a better fit and reduce the likelihood of friction and pressure points."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Maintain Ear Hygiene"), ": Keep your ears clean and dry. Avoid inserting cotton swabs or other objects into your ears, as this can push earwax further in and cause irritation. Instead, use ear drops designed to soften and remove earwax if necessary."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Use Anti-Itch Ear Drops"), ": Over-the-counter anti-itch ear drops can provide relief. These drops typically contain ingredients that soothe irritated skin and reduce inflammation."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Take Breaks"), ": If your ears feel particularly itchy or irritated, give them a break from wearing hearing aids. Removing the devices for a few hours can allow your ears to recover."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Consult Your Audiologist"), ": If the itching persists, consult your audiologist. They can check for any underlying issues, such as infections or significant earwax buildup, and provide appropriate treatments."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist-from-hearcom",
    "aria-label": "contact a hearing specialist from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing specialist from hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we are committed to providing the highest standard of care. Our team comprises only top hearing specialists and the top 2% of audiologists in the United States. We are here to assist you with recommendations and guidance on your journey to better hearing. If you’re experiencing issues with itchy ears or have any other concerns about your hearing aids, contact hear.com today. Let us help you achieve optimal hearing health with expert advice and support. Your path to better hearing starts with hear.com."), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Contact a hearing specialist",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
